import React from 'react';
import Card from '../components/Card';
import Container from '../components/Container';
import Layout from "../components/layouts"
import { Scrollbars } from 'react-custom-scrollbars';
import { Helmet } from 'react-helmet';
import {graphql} from 'gatsby'

function legal({ data }) {
  return (
    <>
    <Helmet>
      <title>Classify — Legal</title>
      <meta name="description" content="Classify is dedicated to ensuring proper guidance and guidelines are adhered to. Here is our privacy policy and T&Cs." />
      <link rel="canonical" href="https://classify.org.uk/legal" />

      <meta property="og:title" content="Classify — Legal"/>
      <meta property="og:url" content="https://classify.org.uk/legal"/>
      <meta property="og:image" content="https://i.imgur.com/ODTJzzm.png"/>
      <meta property="og:description" content="Classify is dedicated to ensuring proper guidance and guidelines are adhered to. Here is our privacy policy and T&Cs."/>
      <meta property="og:type" content="website"/>

      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:site" content="@classifyapp"/>
      <meta property="twitter:title" content="Classify — Legal"/>
      <meta name="twitter:image" content="https://i.imgur.com/ODTJzzm.png"/>
      <meta property="twitter:description" content="Classify is dedicated to ensuring proper guidance and guidelines are adhered to. Here is our privacy policy and T&Cs."/>
    </Helmet>
    <Layout>
      <section id="legal" className="bg-legal">
        <Container no-vertical>
          <div className="grid gap-12 text-center py-12 lg:py-24">
            <h1>🔒</h1>
            <p className="text-xl">
              Classify is dedicated to ensuring proper guidance and guidelines are adhered to.  Here is our privacy policy and T&Cs.
            </p>
            <h2>Privacy Policy</h2>
            <Card>
              <div className="py-8 px-4"> 
                <Scrollbars style={{height: '24rem'}}>
                <div className="px-8 text-left whitespace-pre-wrap" dangerouslySetInnerHTML={{__html: data.allPlainText.nodes.find(node => node.parent.name === "privacy_policy").content}} />
                </Scrollbars>
              </div>
            </Card>
            <h2>Terms and Conditions</h2>
            <Card>
              <div className="py-8 px-4"> 
                <Scrollbars style={{height: '24rem'}}>
                  <div className="px-8 text-left whitespace-pre-wrap" dangerouslySetInnerHTML={{__html: data.allPlainText.nodes.find(node => node.parent.name === "tc").content}} />
                </Scrollbars>
              </div>
            </Card>
          </div>
        </Container>
      </section>
    </Layout>
    </>
  );
}

export default legal;

export const pageQuery = graphql`
  query MyQuery {
    allPlainText(filter: {}) {
      nodes {
        parent {
          id
          ... on File {
            name
          }
        }
        content
      }
    }
  }
`