import React from 'react'
import '../../styles/global.scss'
import AlertBar from '../AlertBar'
import AlertConfig from '../../ALERTBAR_CONFIG'
import Footer from '../Footer'
import Navbar from '../navigation/navbar'

function Index(props) {
	return (
		<div style={{ maxWidth: '100vw' }}>
			{AlertConfig.enabled && <AlertBar />}
			<Navbar />
			<div style={{ marginTop: AlertConfig.enabled ? '100px' : '60px' }}>
				{props.children}
			</div>
			<Footer />
		</div>
	)
}

export default Index
